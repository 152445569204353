import { Injectable, NgZone } from '@angular/core';
import { IChangeRegistry } from '@smarttypes/api';
import { IGuest } from '@smarttypes/hotel';
import { IMessageItem } from '@smarttypes/messages';
import { parseISO } from 'date-fns';
import { BehaviorSubject, delay, distinctUntilChanged, map, Observable, of, retryWhen, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import urlJoin from 'url-join';

export interface IChangeRegistryObj<T = unknown> extends IChangeRegistry {
  lastObject?: T;
  updatedAt?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class SseEventsService {
  private _registry: Map<string, BehaviorSubject<unknown>> = new Map();

  get $MessageItemCreate() {
    return (
      this._registry.get('MessageItemcreate') as BehaviorSubject<IChangeRegistryObj<IMessageItem>>
    )?.asObservable();
  }

  get $GuestCreate() {
    return (this._registry.get('Guestcreate') as BehaviorSubject<IChangeRegistryObj<IGuest>>)?.asObservable();
  }

  get $GuestChange() {
    return (this._registry.get('Guestupdate') as BehaviorSubject<IChangeRegistryObj<IGuest>>)?.asObservable();
  }

  constructor(
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly _zone: NgZone,
  ) {
    this._registry.set('MessageItemcreate', new BehaviorSubject<unknown>({ updatedAt: new Date() }));
    this._registry.set('Guestcreate', new BehaviorSubject<unknown>({ updatedAt: new Date() }));
    this._registry.set('Guestupdate', new BehaviorSubject<unknown>({ updatedAt: new Date() }));

    this.authService.$tokenChanged
      .pipe(
        switchMap(token => {
          if (token && this.authService.refreshToken && this.authService.isExpired) {
            return this.authService
              .refreshJWTToken(this.authService.refreshToken)
              .pipe(map(() => this.authService.token));
          } else if (!token || this.authService.isExpired) {
            return of(null);
          }
          return of(token);
        }),
        distinctUntilChanged(),
        switchMap(value => this.source(value)),
        retryWhen(errors => errors.pipe(delay(5000))),
      )
      .subscribe(result => {
        if (result) {
          const date = parseISO(result.updatedAt as unknown as string);
          const key = `${result.collectionName}${result.action}`;

          if (this._registry.has(key)) {
            const current = this._registry.get(key) as BehaviorSubject<IChangeRegistryObj>;
            if (!current?.value?.updatedAt || current.value?.updatedAt < date) {
              if (result?.lastObject) {
                result.lastObject = JSON.parse(result.lastObject as string);
              }
              result.updatedAt = date as Date;
              this._registry?.get(key)?.next(result);
            }
          }
        }
      });
  }

  private source(token?: string | null) {
    if (!token) {
      return of();
    }
    return new Observable<IChangeRegistryObj>(observer => {
      const eventSource = this.getEventSource(urlJoin(environment.apiUrl, 'sse', 'changes'));
      eventSource.onmessage = (event: { data: string }) => {
        this._zone.run(() => {
          observer.next(JSON.parse(event.data) as IChangeRegistryObj);
        });
      };
      eventSource.onerror = (error: unknown) => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
      return () => {
        if (eventSource.readyState === 1) {
          eventSource.close();
          observer.complete();
        }
      };
    });
  }

  private getEventSource(url: string): EventSource {
    return new EventSource(`${url}?sh-at=${this.authService.token}`);
  }
}
